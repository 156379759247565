import { navigate } from "gatsby"

const IndexPage = () => {
  if (typeof window === "undefined") {
    return null
  }

  navigate("/")
  return null
}

export default IndexPage
